<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_conseq_account.title') }}:
						<span v-if="investmentsConseqAccount.accountName">{{ investmentsConseqAccount.accountName }} -</span>
						{{ investmentsConseqAccount.contract_info.ContractNumber }}
					</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments_conseq.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<chart-panels-group :panels-data="panelsData" :charts-data="chartsData" v-if="panelsData.length"/>
					<p v-else>{{ $t('investments.no_data') }}</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import investmentsMixin from "@/mixins/investmentsMixin";
import ActionsPanel from "@/components/panels/ActionsPanel";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsConseqAccount",
	components: {
		ActionsPanel,
		ChartPanelsGroup,
		PanelBoxesContainer
	},
	mixins: [investmentsMixin],
	computed: {
		investmentsConseqAccount() {
			return this.$store.state.views.investmentsConseqAccount;
		},
		products() {
			let data = [];
			this.investmentsConseqAccount.contract_info.valuation.forEach(item => {
				data.push(item);
			});

			return data;
		},
		chartsData() {
			let data = [
				this.balanceChart,
				this.portfoliosChart,
			];

			return data;
		},
		balanceChart() {
			let data = this.getBalanceChartOptions();

			let series = [
				{
					name: this.$t('investments.account_balance'),
					data: [],
				},
				{
					name: this.$t('investments.transactions'),
					data: [],
				}
			];

			this.investmentsConseqAccount.history.forEach(item => {
				let amount = parseFloat(item.BaseValuation);
				series[0].data.push({
					x: item.ValuationDate,
					y: amount.toFixed(2),
				});
			});

			let totalDeposits = 0;
			let totalWithdrawals = 0;
			let totalBalance = 0;
			let totalFees = 0;
			let balance = 0;
			let deposits = this.investmentsConseqAccount.deposits.reverse();
			deposits.forEach(item => {
				let amount = parseFloat(item.AccountCurrAmount);
				if (item.OperationType === 'DEB') {
					if (item.OperationDescrip.includes('poplatek')){
						totalFees += amount;
					} else {
						totalWithdrawals += amount;
					}

					amount = amount * -1;
				} else {
					totalDeposits += amount;
				}
				balance += amount;
				series[1].data.push({
					x: item.SettlementDate,
					y: balance.toFixed(2),
				});
			});

			let currentDate = new Date().toISOString().slice(0, 10);
			series[0].data.push({
				x: currentDate,
				y: this.totalAccountBalance,
			});

			series[1].data.push({
				x: currentDate,
				y: balance.toFixed(2),
			});

			data.series = series;

			data.totalBalance = this.totalAccountBalance;
			data.totalDeposits = totalDeposits;
			data.totalWithdrawals = totalWithdrawals;
			data.totalFees = totalFees;
			data.totalReturn = this.totalAccountBalance - (data.totalDeposits - data.totalWithdrawals);

			return data;
		},
		portfoliosChart() {
			let data = this.getPortfoliosChartOptions();

			let series = [];
			let labels = [];
			this.products.forEach(item => {
				series.push(parseFloat(item.Valuation));
				labels.push(item.ProductName);
			});

			data.chartOptions.labels = labels;
			data.series = series;

			return data;
		},
		panelsData() {
			let data = [];
			this.investmentsConseqAccount.contract_info.valuation.forEach(item => {
				let currency = this.getCurrencySymbol(item.PriceCurr);

				let totalBalance = 0;
				let transactions = this.transactions.filter((transaction) => {
					return item.ISIN === transaction.ISIN;
				});

				transactions.forEach(transaction => {
					switch (transaction.OperationType){
						case 'SUB':
						case 'TON':
							totalBalance += parseFloat(transaction.DealAmount);
							break;
						case 'RED':
						case 'TOFF':
							totalBalance -= parseFloat(transaction.DealAmount);
							break;
					}
				});

				let returnPercentage = (parseFloat(item.Valuation) / totalBalance) * 100 - 100;

				data.push({
					id: item.id,
					category: this.$t('investments.fund'),
					name: item.ProductName,
					description: this.$t('investments.fund_balance'),
					price: item.Valuation,
					returnPercentage: item.ISIN ? returnPercentage : false,
					type: 'portfolio',
					portfolioType: 'conseq',
					isin: item.ISIN,
					currency: currency,
					detail: !!item.ISIN,
				});
			});

			return data;
		},
		transactions(){
			return this.investmentsConseqAccount.transactions.reverse();
		},
		totalAccountBalance() {
			if (!this.investmentsConseqAccount) {
				return 0;
			}

			let total = 0;
			this.products.forEach(item => {
				total += parseFloat(item.BaseValuation);
			});

			return total.toFixed(2);
		},
	}
}
</script>

<style scoped>

</style>