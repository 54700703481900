<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments_eic_account.title') }}:
						<span v-if="investmentsEicAccount.accountName">{{ investmentsEicAccount.accountName }} -</span>
						{{ investmentsEicAccount.accId }}</h2>
				</header>
				<article>
					<actions-panel>
						<router-link class="button style-1" :to="$t('routes.investments_eic.path')">
							<span class="inner">{{ $t('general.back') }}</span>
						</router-link>
					</actions-panel>
					<chart-panels-group :panels-data="panelsData" :charts-data="chartsData" v-if="portfolios.length"/>
					<p v-else>{{ $t('investments.no_data') }}</p>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import investmentsMixin from "@/mixins/investmentsMixin";
import ActionsPanel from "@/components/panels/ActionsPanel";
import ChartPanelsGroup from "@/components/containers/ChartPanelsGroup";
import PanelBoxesContainer from "@/components/containers/PanelBoxesContainer";

export default {
	name: "InvestmentsEicAccount",
	components: {
		ActionsPanel,
		ChartPanelsGroup,
		PanelBoxesContainer
	},
	mixins: [investmentsMixin],
	computed: {
		investmentsEicAccount() {
			return this.$store.state.views.investmentsEicAccount;
		},
		transactions() {
			let bankTransactions = this.investmentsEicAccount.bankTransactions.map(item => {
				return {
					data: item,
					type: 'bank',
					date: item.createdDate
				};
			});

			let tradeTransfers = this.investmentsEicAccount.tradingUnitTransfers.map(item => {
				return {
					data: item,
					type: 'transfer',
					date: item.createdDate
				};
			});

			let transactions = tradeTransfers.concat(bankTransactions);
			transactions.sort((a, b) => {
				return new Date(b.date) - new Date(a.date);
			});

			return transactions.reverse();
		},
		portfolios() {
			let data = [];
			this.investmentsEicAccount.currentPortfolio.forEach(item => {
				if (item.type !== 'Standard' || !item.amount) {
					return;
				}

				data.push(item);
			});

			return data;
		},
		chartsData() {
			let data = [
				this.balanceChart,
				this.portfoliosChart,
			];

			return data;
		},
		balanceChart() {
			let data = this.getBalanceChartOptions();

			let series = [
				{
					name: this.$t('investments.account_balance'),
					data: [],
				},
				{
					name: this.$t('investments.transactions'),
					data: [],
				}
			];

			let currentDate = new Date().toISOString().slice(0, 10);

			this.investmentsEicAccount.history.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});

			this.investmentsEicAccount.history.forEach(item => {
				series[0].data.push({
					x: item.date,
					y: item.balance,
				});
			});

			series[0].data.push({
				x: currentDate,
				y: this.totalAccountBalance,
			});

			let deposit = 0;

			let totalDeposits = 0;
			let totalWithdrawals = 0;

			let transactions = [];
			this.transactions.forEach(item => {
				let amount;
				if (item.type === 'bank') {
					amount = parseFloat(item.data.refAmount.volume);
					if (item.data.creditDebet === 'Debet') {
						amount = amount * -1;
						totalWithdrawals += item.data.refAmount.volume;
					} else {
						totalDeposits += item.data.refAmount.volume;
					}
				} else {
					amount = parseFloat(item.data.amount);
					let note = item.data.note;
					note = note.toLowerCase();
					if (note.includes('prevod')) {
						if (item.data.status === 'Executed' || item.data.status === 'Booked') {
							amount = item.data.crAccRefAmount.volume;
							if (item.data.creditAccount.accId !== this.investmentsEicAccount.accId) {
								totalWithdrawals += amount;
								amount = amount * -1;
							} else {
								totalDeposits += amount;
							}
						}
					}
				}

				transactions.push({
					amount: amount,
					date: item.date,
				});
			});
			transactions = transactions.sort((a, b) => {
				return new Date(a.date) - new Date(b.date);
			});

			transactions.forEach(item => {
				deposit += item.amount;
				series[1].data.push({
					x: item.date,
					y: deposit.toFixed(2),
				});
			});

			series[1].data.push({
				x: currentDate,
				y: deposit.toFixed(2),
			});

			data.series = series;
			data.totalBalance = this.totalAccountBalance;
			data.totalDeposits = totalDeposits;
			data.totalWithdrawals = totalWithdrawals;
			data.totalFees = this.totalFees;
		
			data.totalReturn = this.totalAccountBalance - (totalDeposits - totalWithdrawals) + parseFloat(this.entryFees);

			return data;
		},
		portfoliosChart() {
			let data = this.getPortfoliosChartOptions();

			let series = [];
			let labels = [];
			this.portfolios.forEach(item => {
				series.push(item.refTotalAmount);
				labels.push(item.ticker.ticker);
			});

			data.chartOptions.labels = labels;
			data.series = series;

			return data;
		},
		panelsData() {
			let data = [];
			this.investmentsEicAccount.currentPortfolio.forEach(item => {
				if (item.type !== 'Standard' || !item.amount) {
					return;
				}

				let currency = this.getCurrencySymbol(item.currency.ticker);

				data.push({
					id: item.id,
					category: this.$t('investments.portfolio'),
					name: item.ticker.ticker,
					description: this.$t('investments.portfolio_balance'),
					price: item.totalAmount,
					returnPercentage: item.totalReturnInPercent,
					type: 'portfolio',
					portfolioType: 'eic',
					currency: currency,
					detail: !!item.ticker.isin,
				});
			});

			return data;
		},
		totalAccountBalance() {
			if (!this.investmentsEicAccount) {
				return 0;
			}

			let total = 0;
			let validTypes = [
				'Standard',
				'Blocked',
				'Blokované'
			];
			this.portfolios.forEach(item => {
				if(validTypes.includes(item.type)){
					total += item.refTotalAmount;
				}
			});

			return total.toFixed(2);
		},
		totalFees() {
			if (!this.investmentsEicAccount) {
				return 0;
			}

			let total = 0;
			this.investmentsEicAccount.fees.forEach(item => {
				if (item.feeType === 'ExecuteFee' || item.feeType === 'EntryFee') {
					total += item.refAmount;
				}
			});

			return total.toFixed(2);
		},

		entryFees(){
			if (!this.investmentsEicAccount) {
				return 0;
			}

			let total = 0;
			this.investmentsEicAccount.fees.forEach(item => {
				if (item.feeType === 'EntryFee') {
					total += item.refAmount;
				}
			});

			return total.toFixed(2);
		}
	},
}
</script>

<style scoped>

</style>