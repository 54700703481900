<template>
	<div class="chart-boxes-container" :class="tabs ? 'tabs' : ''">
		<div class="chart-actions" v-if="tabs">
			<button class="change-chart" v-for="(item, index) in data" :key="index" @click="changeTab(index)" :class="index === activeTab ? 'active' : ''">
				{{ item.tabName }}
			</button>
		</div>
		<chart-box v-for="(item, index) in data" :key="index" :data="item" v-if="item.series.length" v-show="activeTab === index"/>
	</div>
</template>

<script>
import ChartBox from "@/components/containers/ChartBox";

export default {
	name: "ChartBoxesContainer",
	props: ['data', 'tabs'],
	components: {
		ChartBox,
	},
	data(){
		return {
			activeTab: 0,
		}
	},
	methods: {
		changeTab(index){
			this.activeTab = index;
		}
	}
}
</script>

<style scoped lang="scss">
.chart-boxes-container {
	position: relative;
	&:not(.tabs){
		@media($desktopM) and (min-width: 992px){
			display: flex;
			justify-content: space-between;
			.chart-box {
				width: calc(50% - (15px / 2));
				margin-bottom: 0;
				&:nth-child(n + 3 ){
					margin-top: 15px;
				}
			}
		}
	}
	&.tabs {
		@media(max-width: 1220px){
			.chart-box {
				//width: 50%;
				max-width: 500px;
			}
			.chart-actions {
				//max-width: 50%;
				max-width: 500px;
			}
		}
	
		@media(max-width: 991px){
			.chart-box {
				max-width: 100%;
			}
			.chart-actions {
				max-width: 100%;
			}
		}
	}
	
}
.chart-box {
	margin-bottom: 15px;
	&:last-child {
		margin-bottom: 0;
	}
}
/*.chart-actions {
	position: absolute;
	left: 10px;
	top: 10px;
	z-index: 100;
}
.change-chart {
	font-size: 12px;
	background: transparent;
	border: none;
	color: var(--primary-color, $primaryColor);
	display: inline-block;
	padding: 4px 10px;
	line-height: 1;
	border: 1px solid var(--primary-color, $primaryColor);
	border-radius: 4px;
	margin-right: 5px;
	cursor: pointer;
	transition: $animationDefaultDuration;
	&:last-child {
		margin-right: 0;
	}
	&.active,
	&:hover {
		background: var(--primary-color, $primaryColor);
		color: var(--white-color, $whiteColor);
	}
}*/
.light-mode {
	.chart-actions {
		.change-chart {
			color: var(--secondary-font-color, $secondaryFontColor);
			&:hover,
			&.active {
				color: var(--backgroundColor, $backgroundColor);
			}
			&:after{
				background: rgba(var(--background-color-rgb), 0.1);
			}
		}
	}
}
.chart-actions {
	display: flex;
	gap: 5px;
	margin-block: 15px;
	.change-chart {
		border: none;
		background: transparent;
		//color: var(--white-color, $whiteColor);
		color: var(--font-color, $fontColor);
		flex: 1;
		position: relative;
		padding-bottom: 7px;
		//font-size: 14px;
		cursor: pointer;
		transition: $animationDefaultDuration;
		&:after {
			content: '';
			bottom: 0;
			height: 2px;
			left: 0;
			right: 0;
			background: rgba(var(--white-color-rgb), 0.1);
			position: absolute;
			display: block;
			border-radius: 5px;
			z-index: 1;
		}
		&:before {
			content: '';
			bottom: 0;
			height: 2px;
			left: 0;
			width: 0;
			background: var(--primary-color, $primaryColor);
			position: absolute;
			display: block;
			border-radius: 5px;
			z-index: 2;
			transition: $animationDefaultDuration;
		}
		&:hover {
			color: var(--white-color, $whiteColor);
		}
		&.active {
			color: var(--white-color, $whiteColor);
			&:before {
				width: 100%;
			}
		}
	}
}
</style>