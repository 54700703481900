import { render, staticRenderFns } from "./InvestmentsConseq.vue?vue&type=template&id=59223e0a&scoped=true"
import script from "./InvestmentsConseq.vue?vue&type=script&lang=js"
export * from "./InvestmentsConseq.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59223e0a",
  null
  
)

export default component.exports