<template>
    <div class="investment-info-box">
        <component :is="data.redirectDetail ? 'router-link' : 'div'" :to="data.detailUrl" class="inner">
            <div class="top-part">
                <div class="text">
                    <h3 class="investment-info-title">{{ data.name }}</h3>
                    <p>{{ $t('investments.investment_balance')}}</p>
                </div>
                <div class="info-box">
                    <p class="value">{{ formatNumber(data.total) }} {{ chosenCurrency }}</p>
                    <p class="name" :class="percentageClass">{{ data.profitPercentage }}%</p>
                </div>
                <div class="actions" v-if="!data.hideStats">
                    <button type="button" class="toggle-stats" :class="showStats ? 'show' : ''" @click.stop.prevent="showStats = !showStats">
                        <i class="fa-light fa-angle-down"></i>
                    </button>
                </div>
            </div>
            <div class="bottom-part" v-if="!data.hideStats && showStats">
                <div class="info-box">
                    <p class="value">{{ formatNumber(data.deposits) }} {{ chosenCurrency }}</p>
                    <p class="name">{{ $t('investments.deposits')}}</p>
                </div>
                <div class="info-box">
                    <p class="value">{{ formatNumber(data.withdrawals) }} {{ chosenCurrency }}</p>
                    <p class="name">{{ $t('investments.withdrawals')}}</p>
                </div>
                <div class="info-box">
                    <p class="value">{{ formatNumber(data.fees) }} {{ chosenCurrency }}</p>
                    <p class="name">{{ $t('investments.fees')}}</p>
                </div>
                <div class="info-box">
                    <p class="value">{{ formatNumber(data.profit) }} {{ chosenCurrency }}</p>
                    <p class="name">{{ $t('investments.return')}}</p>
                </div>
            </div>
        </component>
    </div>
</template>

<script>
import pageFunctionsMixin from '../../mixins/pageFunctionsMixin';
export default {
    name: "InvestmentInfoBox",
    props: ['data'],
    mixins: [pageFunctionsMixin],
    data(){
        return {
            showStats: false
        }
    },
    computed: {
        chosenCurrency() {
            if (this.data.currency){
                return this.data.currency;
            }
            return this.$store.state.app.currency;
        },
        percentageClass(){
            let percentage = parseFloat(this.data.profitPercentage);

            if(percentage === 0){
                return 'neutral';
            } else if(percentage > 0){
                return 'success';
            } else {
                return 'error';
            }
        }
    }
}
</script>

<style lang="scss">
.light-mode {
    .investment-info-box {
        background: #F0F0F2;
        .investment-info-title,
        .value {
            color: var(--background-color, $backgroundColor);
        }
        .text,
        .name {
            color: var(--secondary-font-color,#808795);
        }
        .bottom-part {
            border-top: 1px solid rgba(var(--background-color-rgb), 0.15);
        }
        .actions {
            .toggle-stats {
                color: var(--background-color, $backgroundColor);
            }
        }
    
    }

}
.investment-info-box {
    background: rgba(var(--white-color-rgb), 0.06);
    border-radius: 5px;
    position: relative;
    .actions {
        position: absolute;
        right: -20px;
        top: calc(50% - 9px);
        .toggle-stats {
            line-height: 1;
            background: transparent;
            border: none;
            color: var(--white-color, $whiteColor);
            cursor: pointer;
            font-size: 18px;
            transition: $animationDefaultDuration;
            &:hover {
                color: var(--primary-color, $primaryColor);
            }
            &.show {
                transform: rotateX(180deg);
            }
        }
    }
    .top-part {
        position: relative;
    }
    .bottom-part {
        display: none;
    }
    a.inner {
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -4px;
            right: -4px;
            bottom: -4px;
            left: -4px;
            pointer-events: none;
            border: 2px solid var(--secondary-color, $primaryColor);
            border-radius: 8px;
            opacity: 0;
            transition: $animationDefaultDuration;
        }
        &:hover {
            &:before {
                opacity: 1;
            }
        }
    }
   
    .inner {
        display: block;
        padding: 24px 30px;
    }
    .top-part {
        display: flex;
        justify-content: space-between;
        align-items: center;
    
        .info-box {
            width: auto;
            text-align: right;
        }
    }
    .bottom-part {
        margin-top: 20px;
        border-top: 1px solid rgba(var(--white-color-rgb), 0.15);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        .value {
            font-size: 16px;
        }
    }
    .info-box {
        width: calc(25% - 10px);
        text-align: center;
        &:first-child {
            text-align: left;
        }
        &:last-child {
            text-align: right;
        }
    }
    .investment-info-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 5px;
    }
    .value {
        color: var(--white-color);
        font-size: 20px;
        white-space: nowrap;
        margin-bottom: 5px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .text {
        color: var(--font-color,  $fontColor);
        font-size: 12px;
    }
    .name {
        font-size: 12px;
        color: var(--font-color,  $fontColor);
        &.success {
            color: var(--success-color, $successColor);
        }
        &.error {
            color: var(--error-color, $errorColor);
        }
    }

    @media($tabletS){
        .inner {
            padding: 20px;
        }
        .top-part {
            flex-direction: column;
            align-items: center;
            .text {
                margin-bottom: 15px;
            }
            .actions {
                right: 0;
            }
            .info-box {
                text-align: center;
                margin-bottom: 0;
            }
        }
        .bottom-part {
            padding-top: 15px;
            flex-wrap: wrap;
            justify-content: space-between;
        }
        .investment-info-title {
            margin-bottom: 5px;
            text-align: center;
        }
        .bottom-part {
            .info-box {
                margin-bottom: 15px;
                width: 50%;
                &:nth-child(1),
                &:nth-child(3){
                    text-align: left;
                }
                &:nth-child(2),
                &:nth-child(4){
                    text-align: right;
                }
                &:nth-child(3),
                &:nth-child(4){
                    margin-bottom: 0;
                }
            }
        }

        
    }
}
</style>