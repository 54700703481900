import { render, staticRenderFns } from "./InvestmentsInvestika.vue?vue&type=template&id=fec5f90a&scoped=true"
import script from "./InvestmentsInvestika.vue?vue&type=script&lang=js"
export * from "./InvestmentsInvestika.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fec5f90a",
  null
  
)

export default component.exports