<template>
	<main class="app-main">
		<div class="content-wrap">
			<section class="content-section">
				<header class="section-header">
					<h2 class="section-title">{{ $t('routes.investments.title') }}</h2>
				</header>
				<article>
					<investments-options-form/>
					<chart-investment-info-group :investment-info-data="investmentsInfoData" :charts-data="chartsData" :tabs="true" v-if="investmentsData"/>
				</article>
			</section>
		</div>
	</main>
</template>

<script>
import InvestmentsOptionsForm from "@/components/forms/InvestmentsOptionsForm";
import ChartInvestmentInfoGroup from "../components/containers/ChartInvestmentInfoGroup";
import investmentsMixin from "../mixins/investmentsMixin";

export default {
	name: "InvestmentsHome",
	components: {
		InvestmentsOptionsForm,
		ChartInvestmentInfoGroup
	},
	mixins: [investmentsMixin],
	data() {
		return {}
	},
	computed: {
		chartsData() {
			let data = [
				{
					tabName: this.$t('investments.allocation'),
					chartOptions: {
						chart: {
							type: 'donut',
						},
						legend: {
							show: true,
							position: 'right',
							fontSize: '16px',
							fontFamily: 'Rubik,sans-serif',
							labels: {
								colors: 'inherit',
							},
							markers: {
								width: 15,
								height: 15,
								radius: 3,
								offsetX: -5,
								offsetY: 2
							},
						},
						colors: [
							'#f9a03a',
							'#2BA748',
							'#008FFB',
							'#FF4560',
							'#775DD0'
						],
						plotOptions: {
							pie: {
								borderWidth: 0
							}
						},
						stroke: {
							show: true,
							curve: 'smooth',
							lineCap: 'butt',
							colors: [
								'#f9a03a',
								'#2BA748',
								'#008FFB',
								'#FF4560',
								'#775DD0'
							],
							width: 2,
							dashArray: 1,
						},
						tooltip: {
							enabled: true,
							offset: 100,
							y: {
								show: true,
								formatter: (seriesName) => {
									return `${seriesName} ${process.env.VUE_APP_CURRENCY}`;
								},
							},
						},
						dataLabels: {
							enabled: true,
							dropShadow: {
								enabled: true,
								top: 2,
								left: 1,
								blur: 2,
								color: '#000',
								opacity: 0.2,
							},
						},
						responsive: [{
							breakpoint: 991,
							options: {
								legend: {
									position: 'bottom',
									markers: {
										offsetX: -2,
										offsetY: 0
									}
								}
							},
						}]
					},
					totalBalance: this.investmentsData.total,
				},
				this.balanceChart,
			];

			let labels = [];
			let series = [];
			if (this.checkPermission('EIC')){
				labels.push('EIC');
				series.push(this.investmentsData.total_eic);
			}
			if (this.checkPermission('IAD')){
				labels.push('IAD');
				series.push(this.investmentsData.total_iad);
			}
			if (this.checkPermission('Conseq')){
				labels.push('Conseq');
				series.push(this.investmentsData.total_conseq);
			}
			if (this.checkPermission('Investika')){
				labels.push('Investika');
				series.push(this.investmentsData.total_investika);
			}

			data[0].chartOptions.labels = labels;
			data[0].series = series;

			return data;
		},
		balanceChart(){
			let data = this.getBalanceChartOptions();

			data.tabName = this.$t('investments.profit');

			let series = [
				{
					name: this.$t('investments.account_balance'),
					data: [],
				},
				{
					name: this.$t('investments.transactions'),
					data: [],
				}
			];

			
			this.investmentsData.global_balance.forEach(item => {
				series[0].data.push({
					x: item.date,
					y: item.amount,
				});
			});

			this.investmentsData.global_transactions.forEach(item => {
				series[1].data.push({
					x: item.date,
					y: item.amount,
				});
			});


			data.series = series;

			return data;
		},
		investmentsData() {
			return this.$store.state.views.investments;
		},
		investmentsInfoData(){
			let boxes = [];

			if (this.checkPermission('EIC')){
				boxes.push({
					name: `EIC`,
					total: this.investmentsData.eic.total.toFixed(2),
					deposits: this.investmentsData.eic.deposits.toFixed(2),
					withdrawals: this.investmentsData.eic.withdrawals.toFixed(2),
					profit: this.investmentsData.eic.profit.toFixed(2),
					profitPercentage: this.investmentsData.eic.profit_percentage.toFixed(2),
					fees: this.investmentsData.eic.fees.toFixed(2),
					hideStats: this.investmentsData.eic.hide_stats,
					detailUrl: this.$t('routes.investments_eic.path'),
					redirectDetail: this.investmentsData.eic.accounts.length,
				});
			}

			if (this.checkPermission('IAD')){
				boxes.push({
					name: `IAD`,
					total: this.investmentsData.iad.total.toFixed(2),
					deposits: this.investmentsData.iad.deposits.toFixed(2),
					withdrawals: this.investmentsData.iad.withdrawals.toFixed(2),
					profit: this.investmentsData.iad.profit.toFixed(2),
					profitPercentage: this.investmentsData.iad.profit_percentage.toFixed(2),
					fees: this.investmentsData.iad.fees.toFixed(2),
					hideStats: this.investmentsData.iad.hide_stats,
					detailUrl: this.$t('routes.investments_iad.path'),
					redirectDetail: this.investmentsData.iad.accounts.length,
				});
			}

			if (this.checkPermission('Conseq')){
				boxes.push({
					name: `Conseq`,
					total: this.investmentsData.conseq.total.toFixed(2),
					deposits: this.investmentsData.conseq.deposits.toFixed(2),
					withdrawals: this.investmentsData.conseq.withdrawals.toFixed(2),
					profit: this.investmentsData.conseq.profit.toFixed(2),
					profitPercentage: this.investmentsData.conseq.profit_percentage.toFixed(2),
					fees: this.investmentsData.conseq.fees.toFixed(2),
					hideStats: this.investmentsData.conseq.hide_stats,
					detailUrl: this.$t('routes.investments_conseq.path'),
					redirectDetail: this.investmentsData.conseq.accounts.length,
				});
			}

			if (this.checkPermission('Investika')){
				boxes.push({
					name: `Investika`,
					total: this.investmentsData.investika.total.toFixed(2),
					deposits: this.investmentsData.investika.deposits.toFixed(2),
					withdrawals: this.investmentsData.investika.withdrawals.toFixed(2),
					profit: this.investmentsData.investika.profit.toFixed(2),
					profitPercentage: this.investmentsData.investika.profit_percentage.toFixed(2),
					fees: this.investmentsData.investika.fees.toFixed(2),
					hideStats: this.investmentsData.investika.hide_stats,
					detailUrl: this.$t('routes.investments_investika.path'),
					redirectDetail: this.investmentsData.investika.accounts.length,
				});
			}

			return boxes;
		}
	},
	methods: {
		checkPermission(type){
			let app = process.env.VUE_APP_TYPE;
			let allowedInvestments = this.$store.state.app.allowedInvestments[app];

			return allowedInvestments.includes(type);
		}
	},
	mounted() {

	}
}
</script>

<style scoped lang="scss">
.investments-options-form {
	max-width: 600px;
	margin-bottom: 15px;
}
</style>