import axios from "axios";
import helpers from "../../helpers/helpers";
import i18n from "../../i18n";
import router from "@/router";

const getDefaultState = () => {
	return {
		products: [],
		articles: [],
		documents: [],
		contracts: [],
		document_categories: [],
		cards: [],
		personal_documents: [],
		personal_document_categories: [],
		card_categories: [],
		onlineServices: [],
		mobilities: [],
		branches: [],
		partners: [],
		protocols: [],
		pages: [],
		productDetail: null,
		articleDetail: null,
		onlineServiceDetail: null,
		mobilityDetail: null,
		pageDetail: null,
		currentDocumentCategory: null,
		investments: null,
		investmentsEicAccount: null,
		investmentsIadAccount: null,
		investmentsConseqAccount: null,
		investmentsInvestikaAccount: null,
		selectedInvestmentsClient: '',
		timestamps: {
			dashboardData: 0,
			documentsData: 0,
			productsData: 0,
			articlesData: 0,
			cardsData: 0,
			contactsData: 0,
			investmentsData: 0,
		}
	};
};

const views = {
	state: getDefaultState(),
	mutations: {
		SET_VIEWS_DEFAULT_STATE(state) {
			Object.assign(state, getDefaultState());
		},
		SET_ARTICLES(state, data) {
			state.articles = data;
		},
		SET_DOCUMENT_CATEGORIES(state, data) {
			state.document_categories = data;
		},
		ADD_PERSONAL_DOCUMENT(state, data) {
			state.personal_documents.push(data);
		},
		ADD_CARD(state, data) {
			state.cards.push(data);
		},
		REMOVE_CARD(state, data) {
			state.cards = state.cards.filter(item => item.id !== data.id);
		},
		REMOVE_DOCUMENT(state, data) {
			state.currentDocumentCategory.documents = state.currentDocumentCategory.documents.filter(item => item.id !== data.id);
		},
		REMOVE_PERSONAL_DOCUMENT(state, data) {
			state.personal_documents = state.personal_documents.filter(item => item.id !== data.id);
		},
		SET_SELECTED_INVESTMENTS_CLIENT(state, data) {
			state.selectedInvestmentsClient = data;
		},
		RESET_TIMESTAMP(state, type){
			state.timestamps[type] = 0;
		}
	},
	actions: {
		setProducts(context, data) {
			data.forEach(item => {
				if (!context.state.products.find(product => product.id === item.id)) {
					context.state.products.push(item);
				}
			});
		},

		setArticles(context, data) {
			data.forEach(item => {
				if (!context.state.articles.find(article => article.id === item.id)) {
					context.state.articles.push(item);
				}
			});
		},

		setDocumentCategories(context, data) {
			let documentCategories = [];
			if (data) {
				documentCategories = data.map(v => ({ ...v, url: `${i18n.t('routes.documents.path')}/${v.id}` }))
			}

			documentCategories.forEach(item => {
				if (!context.state.document_categories.find(category => category.id === item.id)) {
					let data = item;
					data.count = data.contracts_count + data.documents_count;
					context.state.document_categories.push(data);
				}
			});
		},

		setDocumentCategoryData(context, data) {
			context.state.currentDocumentCategory = data;
		},

		setOnlineServicesData(context, data) {
			let onlineServices = [];

			data.forEach(item => {
				if(item.type === 'internal'){
					if(!context.rootState.auth.user.intranet_data){
						return true;
					}

					let action = '';
					if (item.data.check_requirements){
						action = 'checkOnlineServiceRequirements';
					}

					if (item.group){
						let index = onlineServices.findIndex((onlineService) => onlineService.name === item.group);
						if (index !== -1){
							onlineServices[index].group.push({
								id: item.data.id,
								boxImage: item.data.box_image,
								name: item.data.name,
								url: `${i18n.t('routes.online_services.path')}/${item.data.slug}`,
								action,
							});
						} else {
							onlineServices.push({
								name: item.data.group,
								url: '#',
								icon: `<i class="fa-thin ${item.data.icon}"></i>`,
								type: 'service',
								mutation: 'SHOW_ONLINE_SERVICES_SIDEBAR',
								group: [
									{
										id: item.data.id,
										boxImage: item.data.box_image,
										name: item.data.name,
										url: `${i18n.t('routes.online_services.path')}/${item.data.slug}`,
										action
									}
								],
							});
						}
					} else {
						onlineServices.push({
							id: item.data.id,
							name: item.data.name,
							url: `${i18n.t('routes.online_services.path')}/${item.data.slug}`,
							icon: `<i class="fa-thin ${item.data.icon}"></i>`,
							type: 'service',
							group: false,
							action,
						})
					}
				} else {
					let iconClass = 'fa-coins';
					if (item.data.class) {
						iconClass = item.data.class;
					}
					let icon = `<i class="fa-thin ${iconClass}"></i>`;
	
					let tmpObj = {
						url: item.data.hash_complete_link,
						name: item.data.name,
						type: 'service',
						anchor: true,
						target: '_blank',
						icon
					};
	
					onlineServices.push(tmpObj);
				}
			});

			context.state.onlineServices = onlineServices;
		},

		setPersonalDocumentCategories(context, data) {
			data.forEach(item => {
				if (!context.state.personal_document_categories.find(data => data.id === item.id)) {
					context.state.personal_document_categories.push(item);
				}
			});
		},

		setPersonalDocuments(context, data) {
			data.forEach(item => {
				if (!context.state.personal_documents.find(data => data.id === item.id)) {
					context.state.personal_documents.push(item);
				}
			});
		},

		addPersonalDocument(context, personalDocument) {
			if (!context.state.personal_documents.find(data => data.id === personalDocument.id)) {
				context.state.personal_documents.unshift(personalDocument);
			}
		},

		setCards(context, data) {
			data.forEach(item => {
				if (!context.state.cards.find(data => data.id === item.id)) {
					context.state.cards.push(item);
				}
			});
		},

		setCardCategories(context, data) {
			data.forEach(item => {
				if (!context.state.card_categories.find(data => data.id === item.id)) {
					context.state.card_categories.push(item);
				}
			});
		},

		setBranches(context, data) {
			let branches = [];
			Object.keys(data).forEach(key => {
				let tmpObj = data[key];
				tmpObj.url = `${i18n.t('routes.branch.path')}/${key}`;
				branches.push(tmpObj);
			});

			context.state.branches = branches;
		},

		setPartners(context, data) {
			context.state.partners = data;
		},

		setProtocols(context, data) {
			let protocols = [];
			Object.keys(data).forEach(key => {
				let tmpObj = data[key][0];
				tmpObj.hash = key;
				tmpObj.files = [];
				data[key].forEach(item => {
					tmpObj.files.push({
						name: item.typ_dokumentu,
						type: item.typ_dokumentu_id,
						url: item.url_dokumentu
					});
				});

				protocols.push(tmpObj);
			});

			context.state.protocols = protocols;
		},

		setDocuments(context, data) {
			data.forEach(item => {
				if (!context.state.documents.find(data => data.id === item.id)) {
					context.state.documents.push(item);
				}
			});
		},

		setContracts(context, data) {
			data.forEach(item => {
				if (!context.state.contracts.find(data => data.id === item.id)) {
					context.state.contracts.push(item);
				}
			});
		},

		setInvestments(context, data) {
			context.state.investments = data;
		},

		setInvestmentsEicAccount(context, data) {
			context.state.investmentsEicAccount = data;
		},

		setInvestmentsIadAccount(context, data) {
			context.state.investmentsIadAccount = data;
		},

		setInvestmentsConseqAccount(context, data) {
			context.state.investmentsConseqAccount = data;
		},

		setInvestmentsInvestikaAccount(context, data) {
			context.state.investmentsInvestikaAccount = data;
		},

		getProduct(context, id) {
			return new Promise((resolve, reject) => {
				let product = context.state.products.find(item => item.id === parseInt(id));
				if (product) {
					resolve(product);
					return;
				}

				context.commit('SET_APP_LOADING');
				axios.get(`/api/product/${id}`).then(response => {
					product = response.data.product;
					context.state.products.push(product);

					resolve(product);
					context.commit('UNSET_APP_LOADING');
				}).catch((error) => {
					reject(error);
					context.commit('UNSET_APP_LOADING');
				});
			});
		},

		getArticle(context, id) {
			return new Promise((resolve, reject) => {
				let article = context.state.articles.find(item => item.id === parseInt(id));
				if (article) {
					resolve(article);
					return;
				}

				context.commit('SET_APP_LOADING');
				axios.get(`/api/articles/${id}`).then(response => {
					article = response.data.article;
					context.state.products.push(article);

					resolve(article);
					context.commit('UNSET_APP_LOADING');
				}).catch((error) => {
					reject(error);
					context.commit('UNSET_APP_LOADING');
				});
			});
		},

		async getMobilityData(context, id) {
			context.state.mobilityDetail = null;

			let mobility = context.state.mobilities.find(item => item.id === parseInt(id));
			if (mobility) {
				context.state.mobilityDetail = mobility;
				return true;
			}

			context.commit('SET_APP_LOADING');
			await axios.get(`/api/mobility/${id}`).then(response => {
				mobility = response.data.mobility;
				context.state.mobilities.push(mobility);

				context.state.mobilityDetail = mobility;
				context.commit('UNSET_APP_LOADING');
				return true;
			}).catch((error) => {
				//reject(error);
				context.commit('UNSET_APP_LOADING');
				return false;
			});
		},

		async getArticleData(context, id) {
			context.state.articleDetail = null;

			let article = context.state.articles.find(item => item.id === parseInt(id));
			if (article) {
				context.state.articleDetail = article;
				return true;
			}

			context.commit('SET_APP_LOADING');
			await axios.get(`/api/article/${id}`).then(response => {
				article = response.data.article;
				context.state.articles.push(article);

				context.state.articleDetail = article;
				context.commit('UNSET_APP_LOADING');
				return true;
			}).catch((error) => {
				//reject(error);
				context.commit('UNSET_APP_LOADING');
				return false;
			});
		},

		async getPageData(context, slug) {
			context.state.pageDetail = null;

			let page = context.state.pages.find(item => item.slug === slug);
			if (page) {
				context.state.pageDetail = page;
				return true;
			}

			context.commit('SET_APP_LOADING');
			await axios.get(`/api/pages/${slug}`).then(response => {
				page = response.data.page;
				context.state.pages.push(page);

				context.state.pageDetail = page;
				context.commit('UNSET_APP_LOADING');
				return true;
			}).catch(() => {
				context.commit('UNSET_APP_LOADING');
				return false;
			});
		},

		async getProductData(context, id) {
			context.state.productDetail = null;

			let product = context.state.products.find(item => item.id === parseInt(id));
			if (product) {
				context.state.productDetail = product;
				return true;
			}

			context.commit('SET_APP_LOADING');
			await axios.get(`/api/products/${id}`).then(response => {
				product = response.data.product;
				context.state.products.push(product);

				context.state.productDetail = product;
				context.commit('UNSET_APP_LOADING');
				return true;
			}).catch((error) => {
				//reject(error);
				context.commit('UNSET_APP_LOADING');
				return false;
			});
		},

		async getOnlineServiceData(context, slug) {
			context.state.onlineServiceDetail = null;

			//todo services
			/*let service = context.state.services.find(item => item.url === `${i18n.t('routes.services.path')}/${slug}`);
			if (service) {
				context.state.onlineServiceDetail = service;
				return true;
			}*/

			context.commit('SET_APP_LOADING');
			await axios.get(`/api/online-services/${slug}`).then(response => {
				context.state.onlineServiceDetail = response.data;
				context.commit('UNSET_APP_LOADING');
				return true;
			}).catch((error) => {
				context.commit('UNSET_APP_LOADING');
				return false;
			});
		},

		async getProductsData(context) {
			if (!helpers.checkTimestamp(context.state.timestamps.productsData)) {
				return false;
			}

			context.commit('SET_APP_LOADING');
			await axios.get('/api/get-products-data').then(response => {
				context.dispatch('setProducts', response.data.products);
				context.state.timestamps.productsData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getArticlesData(context) {
			if (!helpers.checkTimestamp(context.state.timestamps.articlesData)) {
				return false;
			}

			context.commit('SET_APP_LOADING');
			await axios.get('/api/get-articles-data').then(response => {
				context.dispatch('setArticles', response.data.articles);
				context.state.timestamps.articlesData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getDashboardData(context) {
			if (!helpers.checkTimestamp(context.state.timestamps.dashboardData)) {
				return false;
			}

			context.commit('SET_APP_LOADING');
			await axios.get('/api/get-dashboard-data').then(response => {
				context.dispatch('setOnlineServicesData', response.data.online_services);
				context.dispatch('setProducts', response.data.products);
				context.dispatch('setArticles', response.data.articles);
				context.dispatch('setCards', response.data.cards);
				context.dispatch('setDocumentCategories', response.data.document_categories);
				context.dispatch('setBranches', response.data.branches);
				context.state.timestamps.dashboardData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getDocumentsData(context) {
			if (!helpers.checkTimestamp(context.state.timestamps.documentsData)) {
				return false;
			}

			context.commit('SET_APP_LOADING');
			await axios.get('/api/get-documents-data').then(response => {
				context.dispatch('setDocumentCategories', response.data.document_categories);
				context.dispatch('setProtocols', response.data.protocols);
				context.dispatch('setDocuments', response.data.documents);
				context.dispatch('setContracts', response.data.contracts);
				context.state.timestamps.documentsData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getDocumentCategoryData(context, id) {
			context.commit('SET_APP_LOADING');
			await axios.get(`/api/get-document-category-data/${id}`).then(response => {
				context.dispatch('setDocumentCategoryData', response.data.document_category);
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getCardsData(context) {
			if (!helpers.checkTimestamp(context.state.timestamps.cardsData)) {
				return false;
			}

			context.commit('SET_APP_LOADING');
			await axios.get('/api/get-cards-data').then(response => {
				context.dispatch('setCards', response.data.cards);
				context.dispatch('setCardCategories', response.data.card_categories);
				context.dispatch('setPersonalDocuments', response.data.personal_documents);
				context.dispatch('setPersonalDocumentCategories', response.data.personal_document_categories);
				context.state.timestamps.cardsData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getContactsData(context) {
			if (!helpers.checkTimestamp(context.state.timestamps.contactsData)) {
				return false;
			}

			context.commit('SET_APP_LOADING');
			await axios.get('/api/get-contacts-data?new-partners').then(response => {
				context.dispatch('setBranches', response.data.branches);
				context.dispatch('setPartners', response.data.partners);
				context.state.timestamps.contactsData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getProtocolsData(context) {
			context.commit('SET_APP_LOADING');
			await axios.get('/api/protocols').then(response => {
				context.dispatch('setProtocols', response.data.protocols);
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getInvestmentsData(context, forceUpdate = false) {
			if (!forceUpdate) {
				if (!helpers.checkTimestamp(context.state.timestamps.investmentsData)) {
					return false;
				}
			}

			let url = '/api/get-investments-data';
			if (context.state.selectedInvestmentsClient) {
				url += `?intranet_id=${context.state.selectedInvestmentsClient}`;
			}

			context.commit('SET_APP_LOADING');
			await axios.get(url).then(response => {
				context.dispatch('setInvestments', response.data);
				context.state.timestamps.investmentsData = +new Date();
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getInvestmentsEicAccountData(context, id) {
			context.commit('SET_APP_LOADING');

			let url = `/api/investments/eic/${id}`;
			if (context.state.selectedInvestmentsClient) {
				url += `?intranet_id=${context.state.selectedInvestmentsClient}`;
			}

			await axios.get(url).then(response => {
				context.dispatch('setInvestmentsEicAccount', response.data.data);
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getInvestmentsIadAccountData(context, slug) {
			context.commit('SET_APP_LOADING');

			let url = `/api/investments/iad/${slug}`;
			if (context.state.selectedInvestmentsClient) {
				url += `?intranet_id=${context.state.selectedInvestmentsClient}`;
			}

			await axios.get(url).then(response => {
				context.dispatch('setInvestmentsIadAccount', response.data.data);
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getInvestmentsConseqAccountData(context, id) {
			context.commit('SET_APP_LOADING');

			let url = `/api/investments/conseq/${id}`;
			if (context.state.selectedInvestmentsClient) {
				url += `?intranet_id=${context.state.selectedInvestmentsClient}`;
			}

			await axios.get(url).then(response => {
				context.dispatch('setInvestmentsConseqAccount', response.data.data);
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		async getInvestmentsInvestikaAccountData(context, id) {
			context.commit('SET_APP_LOADING');

			let url = `/api/investments/investika/${id}`;
			if (context.state.selectedInvestmentsClient) {
				url += `?intranet_id=${context.state.selectedInvestmentsClient}`;
			}

			await axios.get(url).then(response => {
				context.dispatch('setInvestmentsInvestikaAccount', response.data.data);
				context.commit('UNSET_APP_LOADING');
			}).catch(error => {
				context.commit('UNSET_APP_LOADING');
			});
		},

		increaseDocumentCategoryCount(context, id) {
			let category = context.state.document_categories.find(category => category.id === parseInt(id));
			if (category) {
				category.count++;
			}
		},

		updateDocumentCategoryCounts(context, ids) {
			if (ids.old === ids.new) {
				return false;
			}

			let oldCategory = context.state.document_categories.find(category => category.id === parseInt(ids.old));
			let newCategory = context.state.document_categories.find(category => category.id === parseInt(ids.new));
			if (oldCategory && newCategory) {
				oldCategory.count--;
				newCategory.count++;
			}
		},

		updateDocument(context, data) {
			data.document.document_category_id = parseInt(data.document.document_category_id);
			data.category_id = parseInt(data.category_id);
			if (data.document.document_category_id !== data.category_id) {
				return false;
			}

			if (!context.state.currentDocumentCategory) {
				return false
			}

			let documentIndex = _.findIndex(context.state.currentDocumentCategory.documents, (document) => document.id === data.document.id);
			if (documentIndex === -1) {
				return false;
			}

			this._vm.$set(context.state.currentDocumentCategory.documents, documentIndex, data.document);
		},

		updateContract(context, data) {
			let contractIndex = _.findIndex(context.state.currentDocumentCategory.contracts, (contract) => contract.id === data.contract.id);
			if (contractIndex === -1) {
				return false;
			}

			context.state.currentDocumentCategory.contracts[contractIndex].name = data.contract.name;
		},

		updatePersonalDocument(context, data) {
			let personalDocumentIndex = _.findIndex(context.state.personal_documents, (personal_document) => personal_document.id === data.personal_document.id);
			if (personalDocumentIndex === -1) {
				return false;
			}

			this._vm.$set(context.state.personal_documents, personalDocumentIndex, data.personal_document);
		},

		updateCard(context, data) {
			let cardIndex = _.findIndex(context.state.cards, (card) => card.id === data.card.id);
			if (cardIndex === -1) {
				return false;
			}

			this._vm.$set(context.state.cards, cardIndex, data.card);
		},

		updateProtocol(context, data) {
			let updatedProtocol = data[0];
			updatedProtocol.hash = data[0].externe_dok_id;
			updatedProtocol.files = [];
			data.forEach(item => {
				updatedProtocol.files.push({
					name: item.typ_dokumentu,
					type: item.typ_dokumentu_id,
					url: item.url_dokumentu
				});
			});

			let protocolIndex = _.findIndex(context.state.protocols, (protocol) => protocol.hash === updatedProtocol.hash);
			if (protocolIndex === -1) {
				return false;
			}

			this._vm.$set(context.state.protocols, protocolIndex, updatedProtocol);
		},

		resetTimestamps(context) {
			for (let item in context.state.timestamps) {
				context.state.timestamps[item] = 0;
			}
		},

		resetArticles(context) {
			context.state.articles = [];
		},

		resetProducts(context) {
			context.state.products = [];
		},

		checkOnlineServiceRequirements(context, data) {
			context.commit('SET_APP_LOADING');

			axios.get(`/api/online-services/check-requirements/${data.id}`).then((response) => {
				if (response.data.allowed) {
					router.push(data.url);
				} else {
					context.commit('UNSET_APP_LOADING');
					this._vm.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: response.data.message
					}).then(() => {
						if (response.data.redirect){
							router.push(response.data.redirect);
						}
					});
				}
			}).catch((error) => {
				context.commit('UNSET_APP_LOADING');
				this._vm.$swal.fire({
					icon: 'error',
					title: 'Oops',
					text: i18n.t('general.default_error')
				})
			});
		}
	},

}

export default views;