<template>
	<div class="panel-box" :class="data.detail || data.redirectDetail ? 'with-detail' : '' "
	     @click.prevent.stop="processDetail">
		<div class="account-info" v-if="data.accountName">
			<small class="account-name" v-if="!editAccountName">{{ accountEditName }}</small>
			<div class="form-group" v-else @click.stop>
				<input type="text" v-model="accountEditName" ref="editAccountField">
			</div>
			<div class="account-actions">
				<button class="edit-account-name" @click.stop.prevent="processEditAccountName">
					<i class="fa-light fa-pen" v-if="!editAccountName"></i>
					<i class="fa-light fa-check" v-else></i>
				</button>
			</div>
		</div>
		<div class="top-panel" v-if="data.category">
			<small class="category">{{ data.category }}</small>
			<small class="date">
				<span class="dot" :class="dot" v-if="data.type === 'contract' || data.type === 'document'"></span>
				{{ data.date }}
			</small>
			<small class="total-return" :class="totalReturnClass" v-if="data.type === 'portfolio' && data.returnPercentage">
				{{ totalReturn }}
			</small>
		</div>
		<div class="content">
			<div class="text-image">
				<figure class="fit-image image" :class="data.imageObjectFit" v-if="data.image">
					<img :src="data.image">
				</figure>
				<div class="text">
					<h4 class="panel-title">{{ data.name }}</h4>
					<p v-if="data.description">{{ data.description }}</p>
				</div>
			</div>
			<div class="info-actions" v-if="(data.price !== undefined && data.price !== null) || data.action">
				<p class="price" v-if="(data.price !== undefined && data.price !== null)">{{ formattedPrice }} {{ chosenCurrency }}</p>
				<div class="actions" v-if="data.action">
					<router-link class="button style-1" :to="data.action.url" v-if="data.action">
						<span class="inner">{{ data.action.title }}</span>
					</router-link>
				</div>
			</div>
			<div class="info-actions" v-if="data.type === 'protocol'">
				<p class="status" :class="`status-${data.protocolData.status}`" v-if="data.protocolData.statusText">
					<i class="fa-regular" :class="statusTextIcon" v-if="statusTextIcon"></i> {{
					data.protocolData.statusText }}
				</p>
				<div class="actions" v-if="statusToSign.includes(data.protocolData.status)">
					<button class="button style-1">
						<span class="inner">{{ $t('protocols.sign_protocol') }}</span>
					</button>
				</div>
			</div>
		</div>
		<button type="button" class="remove-item" v-if="data.detail && data.remove" @click.stop.prevent="removeItem">
			<i class="fa-regular fa-trash"></i>
		</button>
		<div class="actions"
		     v-if="data.action || (data.type === 'protocol' && statusToSign.includes(data.protocolData.status))">
			<router-link class="button style-1" :to="data.action.url" v-if="data.action">
				<span class="inner">{{ data.action.title }}</span>
			</router-link>
			<button class="button style-1"
			        v-if="data.type === 'protocol' &&  statusToSign.includes(data.protocolData.status)">
				<span class="inner">{{ $t('protocols.sign_protocol') }}</span>
			</button>
		</div>
	</div>
</template>

<script>
	import pageFunctionsMixin from "../../mixins/pageFunctionsMixin";
	import protocolMixin from "../../mixins/protocolMixin";
	import funds from "@/data/funds.json";

	export default {
		name: "PanelBox",
		mixins: [pageFunctionsMixin, protocolMixin],
		props: ['data'],
		data() {
			return {
				accountEditName: this.data.accountName,
				editAccountName: false,
			}
		},
		computed: {
			formattedPrice(){
				return this.formatNumber(this.data.price);
			},
			chosenCurrency() {
				if (this.data.currency){
					return this.data.currency;
				}
				return this.$store.state.app.currency;
			},
			dot() {
				if (this.data.type === 'document') {
					return 'grey'
				}

				if (this.data.type === 'contract') {
					let status = this.data.status.kmen;
					if (!status) {
						return 'red';
					}

					return 'green';
				}

				return '';

			},
			totalReturnClass(){
				if (this.data.type !== 'portfolio'){
					return '';
				}

				return (this.data.returnPercentage !== null && this.data.returnPercentage < 0) ? 'down' : 'up';
			},
			totalReturn(){
				if (this.data.type !== 'portfolio'){
					return '';
				}

				if(this.data.returnPercentage === null){
					return '';
				}

				let prefix = '+';
				if(this.data.returnPercentage < 0){
					prefix = '-';
				}

				let percentagePrefix = '+';
				if (this.data.returnPercentage < 0){
					percentagePrefix = '';
				}

				return `${percentagePrefix}${this.formatNumber(this.data.returnPercentage)}%`;
			},
			statusTextIcon() {
				let icon = '';
				switch (this.data.protocolData.status) {
					case '2':
						icon = 'fa-file-xmark';
						break;
					case '6':
					case '3':
						icon = 'fa-clipboard-check';
						break;
				}

				return icon;
			}
		},
		methods: {
			processEditAccountName(){
				if(!this.editAccountName){
					this.editAccountName = true;
					this.$nextTick(() => {
						this.$refs.editAccountField.focus(); 
					});
					return;
				}

				this.axios.post('/api/investments/rename-account', {
					_method: 'PUT',
					name: this.accountEditName,
					type: this.data.type,
					id: this.data.id,
				}).then(response => {
					this.editAccountName = false;
					this.$store.commit('RESET_TIMESTAMP', 'investmentsData');
				}).catch(error => {
					console.log(error);
					if(error.response.data.message){
						this.$swal.fire({
							icon: 'error',
							title: 'Oops',
							text: error.response.data.message
						});
					} else {
						this.$swal.fire({
							icon: 'error',
							title: 'Oops',
							text: this.$t('general.default_error'),
						});
					}
					this.editAccountName = false;
				});
			},
			removeItem() {
				let options = false;
				switch (this.data.type) {
					case 'document':
						options = {
							title: this.$t('general.delete_confirm_title'),
							text: this.$t('general.delete_confirm_text_document'),
							endpoint: `/api/documents/${this.data.id}`,
							commit: 'REMOVE_DOCUMENT'
						};
						break;
					case 'personal-document':
						options = {
							title: this.$t('general.delete_confirm_title'),
							text: this.$t('general.delete_confirm_text_personal_document'),
							endpoint: `/api/personal-documents/${this.data.id}`,
							commit: 'REMOVE_PERSONAL_DOCUMENT'
						};
						break;
				}

				if (!options) {
					return false;
				}

				this.$swal.fire({
					icon: 'question',
					title: options.title,
					text: options.text,
					showDenyButton: true,
					confirmButtonText: this.$t('general.yes'),
					denyButtonText: this.$t('general.no'),
				}).then((result) => {
					if (result.isConfirmed) {
						this.axios.post(options.endpoint, {
							_method: 'DELETE'
						}).then(() => {
							this.$store.commit(options.commit, {
								id: this.data.id
							});
						});
					}
				});
			},
			processDetail() {
				if (this.data.detail) {
					this.showDetail();
					return true;
				}

				if (this.data.redirectDetail) {
					this.redirectToDetail();
					return true;
				}

				return false;
			},
			showDetail() {
				if (!this.data.detail) {
					return false;
				}

				switch (this.data.type) {
					case 'document':
						this.showDocumentDetail();
						break;
					case 'contract':
						this.showContractDetail();
						break;
					case 'personal-document':
						this.showPersonalDocumentDetail();
						break;
					case 'protocol':
						this.showProtocolDetail();
						break;
					case 'portfolio':
						this.showPortfolioDetail();
						break;
				}
			},
			redirectToDetail() {
				if (!this.data.detailUrl) {
					return false;
				}

				this.$router.push(this.data.detailUrl);
				return false;
			},
			showDocumentDetail() {
				this.$store.commit('SET_APP_LOADING');

				let promises = [];
				if (!this.$store.state.views.document_categories.length) {
					let documentCategoriesPromise = this.axios.get(`/api/document-categories`);
					promises.push(documentCategoriesPromise);
				}

				let documentPromise = this.axios.get(`/api/documents/${this.data.id}`);
				promises.push(documentPromise);

				Promise.all(promises).then((values) => {
					values.forEach(response => {
						if (response.data.document_categories) {
							this.$store.dispatch('setDocumentCategories', response.data.document_categories);
						}
						if (response.data.document) {
							if (this.$store.state.sidebars.showDocumentsEditForm) {
								this.$store.commit('HIDE_DOCUMENTS_EDIT_FORM');
								setTimeout(() => {
									this.$store.commit('SHOW_DOCUMENTS_EDIT_FORM', response.data.document);
								}, 400);

								this.$store.commit('UNSET_APP_LOADING');
								return false;
							}

							this.$store.commit('SHOW_DOCUMENTS_EDIT_FORM', response.data.document);
						}
						this.$store.commit('UNSET_APP_LOADING');
					});
				}).catch(() => {
					this.$store.commit('UNSET_APP_LOADING');
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: this.$t('general.default_error')
					});
				});
			},
			showContractDetail() {
				this.$store.commit('SET_APP_LOADING');
				this.axios.get(`/api/contracts/${this.data.id}`).then(response => {
					this.$store.commit('UNSET_APP_LOADING');

					if (this.$store.state.sidebars.showContractsEditForm) {
						this.$store.commit('HIDE_CONTRACTS_EDIT_FORM');
						setTimeout(() => {
							this.$store.commit('SHOW_CONTRACTS_EDIT_FORM', response.data.contract);
						}, 400);

						return false;
					}

					this.$store.commit('SHOW_CONTRACTS_EDIT_FORM', response.data.contract);
				}).catch(error => {
					this.$store.commit('UNSET_APP_LOADING');
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
				});
			},
			showPersonalDocumentDetail() {
				this.$store.commit('SET_APP_LOADING');
				this.axios.get(`/api/personal-documents/${this.data.id}`).then(response => {
					this.$store.commit('UNSET_APP_LOADING');

					if (this.$store.state.sidebars.showPersonalDocumentsEditForm) {
						this.$store.commit('HIDE_PERSONAL_DOCUMENTS_EDIT_FORM');
						setTimeout(() => {
							this.$store.commit('SHOW_PERSONAL_DOCUMENTS_EDIT_FORM', response.data.personal_document);
						}, 400);

						return false;
					}

					this.$store.commit('SHOW_PERSONAL_DOCUMENTS_EDIT_FORM', response.data.personal_document);
				}).catch(error => {
					this.$store.commit('UNSET_APP_LOADING');
					this.$swal.fire({
						icon: 'error',
						title: 'Oops',
						text: error.response.data.message
					});
				});
			},
			showProtocolDetail() {
				let protocol = this.$store.state.views.protocols.find(item => {
					return item.id === this.data.id;
				});

				if (this.$store.state.sidebars.showProtocolDetail) {
					this.$store.commit('HIDE_PROTOCOL_DETAIL');
					setTimeout(() => {
						this.$store.commit('SHOW_PROTOCOL_DETAIL', protocol);
					}, 400);
					return false;
				}

				this.$store.commit('SHOW_PROTOCOL_DETAIL', protocol);
				return false;
			},

			showPortfolioDetail(){
				let data = false;
				switch (this.data.portfolioType){
					case 'eic':
						data = this.getEicPortfolioData();
						break;
					case 'iad':
						data = this.getIadPortfolioData();
						break;
					case 'conseq':
						data = this.getConseqPortfolioData();
						break;
					case 'investika':
						data = this.getInvestikaPortfolioData();
						break;
				}

				if (!data){
					return;
				}

				if (this.$store.state.sidebars.showPortfolioDetail){
					this.$store.commit('HIDE_PORTFOLIO_DETAIL');
					setTimeout(() => {
						this.$store.commit('SHOW_PORTFOLIO_DETAIL', data);
					}, 400);
					return false;
				}

				this.$store.commit('SHOW_PORTFOLIO_DETAIL', data);

				return false;
			},

			getEicPortfolioData(){
				let portfolio = this.$store.state.views.investmentsEicAccount.currentPortfolio.find(item => {
					return item.id === this.data.id;
				});

				let lastPurchase = this.$moment(portfolio.lastPurchase).format('D.M.YYYY');
				let rateDate = this.$moment().format('D.M.YYYY');

				let data = {
					name: portfolio.fundName,
					shortcut: portfolio.ticker.ticker,
					isin: portfolio.ticker.isin,
					amount: portfolio.amount,
					rate: portfolio.rate,
					averageRate: portfolio.averagePurchasePrice,
					rateDate: rateDate,
					lastPurchase: lastPurchase,
					totalReturn: portfolio.totalReturnInPercent,
					totalAmount: portfolio.totalAmount,
				};

				return data;
			},

			getIadPortfolioData(){
				let portfolio = this.$store.state.views.investmentsIadAccount.funds.find(item => {
					return item.ISIN.trim() === this.data.isin;
				});

				let transactions = this.$store.state.views.investmentsIadAccount.transactions.filter(transaction => {
					return portfolio.ISIN === transaction.ISIN;
				});

				transactions = transactions.sort((a, b) => {
					return new Date(a.Datum) - new Date(b.Datum);
				});

				let rate = portfolio.KurzFondu;
				let rateDate = this.$moment(portfolio.Datum).format('D.M.YYYY');
				let totalRate = 0;
				transactions.map(transaction => totalRate += transaction.CenaZaKus);
				let averageRate = totalRate / (transactions.length - 1);
				let lastPurchase = this.$moment(transactions[transactions.length -1].Datum).format('D.M.YYYY');
				let totalBalance = 0;
				transactions.forEach(transaction => {
					switch (transaction.TypOperacie){
						case 'k':
						case 'z':
						case 'sw':
							totalBalance += transaction.ObjemOperacieVmene;
							break;
						case 'p':
							totalBalance -= transaction.ObjemOperacieVmene;
							break;
					}
				});

				let returnPercentage = (portfolio.ObejmInvesticie/ totalBalance) * 100 - 100;

				let name = portfolio.NazovFondu;
				let isin = portfolio.ISIN.trim();
				if (portfolio.NazovFondu.trim() === isin) {
					name = funds[isin];
				}

				let data = {
					name: name,
					isin: isin,
					amount: portfolio.PocetPodielov,
					rate: rate,
					averageRate: averageRate.toFixed(4),
					rateDate: rateDate,
					lastPurchase: lastPurchase,
					totalReturn: `${returnPercentage.toFixed(2)}%`,
					totalAmount: portfolio.ObejmInvesticie,
				};

				return data;
			},

			getConseqPortfolioData(){
				let portfolio = this.$store.state.views.investmentsConseqAccount.contract_info.valuation.find(item => {
					return item.ISIN === this.data.isin;
				});

				let transactions = this.$store.state.views.investmentsConseqAccount.transactions.reverse().filter(transaction => {
					return portfolio.ISIN === transaction.ISIN;
				});
				let rateDate = this.$moment(portfolio.ValuationDate).format('D.M.YYYY');
				let totalRate = 0;
				transactions.map(transaction => totalRate += parseFloat(transaction.Price));
				let averageRate = totalRate / (transactions.length - 1);
				let lastPurchase = this.$moment(transactions[transactions.length -1].DealDate).format('D.M.YYYY');
				let totalBalance = 0;

				transactions.forEach(transaction => {
					switch (transaction.OperationType){
						case 'SUB':
						case 'TON':
							totalBalance += parseFloat(transaction.DealAmount);
							break;
						case 'RED':
						case 'TOFF':
							totalBalance -= parseFloat(transaction.DealAmount);
							break;
					}
				});
				let returnPercentage = (parseFloat(portfolio.Valuation) / totalBalance) * 100 - 100;

				let data = {
					name: portfolio.ProductName,
					isin: portfolio.ISIN,
					amount: portfolio.Holding,
					rate: portfolio.Price,
					averageRate: averageRate.toFixed(4),
					rateDate: rateDate,
					lastPurchase: lastPurchase,
					totalReturn: `${returnPercentage.toFixed(2)}%`,
					totalAmount: portfolio.Valuation,
				};

				return data;
			},

			getInvestikaPortfolioData(){
				let portfolio = this.$store.state.views.investmentsInvestikaAccount.portfolios.find(item => {
					return item.financialinstrumentisin === this.data.id;
				});

				let rate = portfolio.amount / portfolio.quantity;

				let transactions =  this.$store.state.views.investmentsInvestikaAccount.transactions.filter((item) => {
					return item.financialInstrumentIsin === this.data.id;
				});

				let totalTransactions = 0;
				transactions.forEach((transaction) => {
					totalTransactions += parseFloat(transaction.price);
				});

				let averageRate = totalTransactions / transactions.length;
				let currentDate = new Date().toLocaleDateString('sk-SK');

				let lastPurchase = transactions.reverse().find(transition => {
					return transition.type !== 'Odkup CP';
				});
				lastPurchase = this.$moment(lastPurchase.settlementDate).format('D.M.YYYY');

				let totalBalance = 0;
				transactions.forEach(transaction => {
					if (transaction.type === 'Odkup CP'){
						totalBalance-= parseFloat(transaction.amount);
					} else {
						totalBalance+= parseFloat(transaction.amount);
					}
				});

				let returnPercentage = (parseFloat(portfolio.amount) / totalBalance) * 100 - 100;

				let data = {
					name: portfolio.financialinstrumentname,
					isin: portfolio.financialinstrumentisin,
					amount: portfolio.amount,
					rate: rate,
					averageRate: averageRate.toFixed(4),
					rateDate: currentDate,
					lastPurchase: lastPurchase,
					totalReturn: `${returnPercentage.toFixed(2)}%`,
					totalAmount: parseFloat(portfolio.amount).toFixed(2),
				};

				return data;
			}
		}

	}
</script>

<style scoped lang="scss">
	.light-mode {
		.panel-box {
			background: #F0F0F2;

			.remove-item {
				border-color: var(--white-color, $whiteColor);
				background: var(--background-color, $backgroundColor);
				color: var(--white-color, $whiteColor);
			}

			.price {
				color: var(--background-color, $backgroundColor);
			}
		}

		.account-info,
		.top-panel {
			border-bottom: 1px solid rgba(var(--background-color-rgb), 0.15);
		}

		.account-info {
			align-content: center;
		}

		.account-name,
		.category {
			color: var(--background-color, $backgroundColor);
		}

		.date,
		.total-return {
			color: var(--secondary-font-color, $secondaryFontColor);
		}

		.panel-title {
			color: var(--background-color, $backgroundColor);
		}
		.edit-account-name {
			background: var(--background-color, $backgroundColor);
		}

	}

	.default-sidebar {
		.account-name,
		.category {
			font-size: 10px;
		}

		.date,
		.total-return {
			font-size: 9px;
		}

		.panel-title {
			font-size: 12px;
			margin: 0;
		}

		.text-image {
			p {
				font-size: 10px;
			}
		}

		.image {
			width: 40px;
			height: 40px;
			min-width: 40px;
		}

		.account-info,
		.top-panel {
			padding-bottom: 10px;
		}

		.content {
			padding-top: 10px;
		}

		.panel-box {
			padding: 20px 15px;

			> .actions {
				display: block;
			}
		}

		.info-actions {
			.actions {
				display: none;
			}
		}

		.price {
			font-size: 16px;
		}
	}

	.panel-box {
		padding: 25px 30px;
		background: rgba(var(--white-color-rgb), .06);
		border-radius: 5px;

		.account-actions {
			line-height: 1;
		}

		.edit-account-name {
			background: var(--white-color, $whiteColor);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			width: 30px;
			height: 30px;
			font-size: 12px;
			position: relative;
			border: none;
			//border: 5px solid var(--background-color, $backgroundColor);
			color: var(--background-color, $backgroundColor);
			cursor: pointer;
			transition: $animationDefaultDuration;

			&:hover {
				color: var(--white-color, $whiteColor);
				background: var(--primary-color, $primaryColor);
			}
		}

		/*.edit-account-name {
			color: var(--primary-color, $primaryColor);
			background: transparent;
			border: none;
			line-height: 1;
			cursor: pointer;
			transition: $animationDefaultDuration;
			font-size: 12px;
			&:hover {
				color: var(--white-color, $whiteColor);
			}
		}*/

		.dot {
			width: 8px;
			height: 8px;
			border-radius: 50%;
			display: inline-block;
			margin-right: 5px;

			&.grey {
				background: #878E9B;
			}

			&.red {
				background: #F2214C;
			}

			&.green {
				background: #95C44F;
			}
		}

		.status {
			text-transform: uppercase;
			font-size: 14px;
			font-weight: 600;

			i {
				margin-right: 5px;
			}

			&.status-1,
			&.status-4,
			&.status-6 {
				color: $primaryColor;
			}

			&.status-2 {
				color: $errorColor;
			}

			&.status-3 {
				color: $successColor;
			}
		}

		.remove-item {
			background: var(--white-color, $whiteColor);
			display: flex;
			align-items: center;
			justify-content: center;
			border-radius: 100%;
			width: 38px;
			height: 38px;
			font-size: 14px;
			border: 5px solid var(--background-color, $backgroundColor);
			color: var(--primary-color, $primaryColor);
			cursor: pointer;
			transition: $animationDefaultDuration;
			position: absolute;
			right: -19px;
			top: -19px;
			opacity: 0;
			pointer-events: none;

			&:hover {
				color: var(--white-color, $whiteColor);
				background: var(--primary-color, $primaryColor);
			}
		}

		&.with-detail {
			cursor: pointer;
			transition: $animationDefaultDuration;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				top: -4px;
				right: -4px;
				bottom: -4px;
				left: -4px;
				pointer-events: none;
				border: 2px solid var(--secondary-color, $primaryColor);
				border-radius: 8px;
				opacity: 0;
				transition: $animationDefaultDuration;
			}

			&:hover {
				.remove-item {
					opacity: 1;
					pointer-events: auto;
				}

				&:before {
					opacity: 1;
				}
			}
		}

		> .actions {
			display: none;
			margin-top: 16px;

			.button {
				width: 100%;
				padding: 9px 25px;
				font-size: 12px;
			}
		}

		@media($tabletS) {
			> .actions {
				margin-top: 0;
			}
		}
	}

	.account-info,
	.top-panel {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding-bottom: 20px;
		//border-bottom: 1px solid transparentize($whiteColor, .85);
		border-bottom: 1px solid rgba(var(--white-color-rgb), .15);
	}

	.account-info {
		padding-bottom: 12px;
		.form-group {
			width: calc(100% - 40px);
		}
		input {
			border-radius: 0;
			border: none;
			padding: 0;
			font-weight: 400;
			font-size: 12px;
		}
	}

	.account-name,
	.category {
		font-size: 12px;
		color: var(--white-color, $whiteColor);
	}

	.date,
	.total-return {
		font-size: 12px;
		color: var(--font-color, $fontColor);
	}

	.total-return {
		&.up {
			color: var(--success-color, $successColor);
		}
		&.down {
			color: var(--error-color, $errorColor);
		}
	}

	.content {
		padding-top: 20px;
		display: flex;
		justify-content: space-between;
		align-items: center;

		&:first-child {
			padding-top: 0;
		}
	}

	.text-image {
		display: flex;
		align-items: center;

		p {
			font-size: 12px;
		}
	}

	.image {
		border-radius: 50%;
		height: 50px;
		width: 50px;
		min-width: 50px;
		overflow-y: hidden;
		margin-right: 20px;
	}

	.price {
		font-size: 20px;
		color: var(--white-color, $whiteColor);
		margin: 0 25px 0 0;
		white-space: nowrap;

		&:last-child {
			margin-right: 0;
		}
	}

	.panel-title {
		margin: 0 0 5px;
		font-size: 14px;
		font-weight: 600;
		color: var(--white-color, $whiteColor);

		&:last-child {
			margin-bottom: 0;
		}
	}

	.info-actions {
		display: flex;
		align-items: center;
		margin-left: 15px;

		.button {
			padding: 12px 35px;
			font-size: 12px;
			width: 300px;
		}
	}

	@media($desktopM) {
		.info-actions {
			.button {
				width: initial;
			}
		}
	}

	@media($tabletL) {
		.price {
			margin-right: 0;
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}
		.info-actions {
			flex-direction: column;
		}
	}

	@media($tabletS) {
		.content {
			flex-direction: column;
			justify-content: center;
		}
		.text-image {
			width: 100%;
			margin-bottom: 20px;
			justify-content: center;

			&:last-child {
				margin-bottom: 0;
			}
		}
		.info-actions {
			width: 100%;
			margin-left: 0;
			flex-direction: row;
			justify-content: center;

			.price {
				margin-right: 20px;
				margin-bottom: 0;

				&:last-child {
					margin-right: 0;
				}
			}
		}
	}

	@media($mobileM) {
		.panel-box {
			padding: 20px;
		}

		.top-panel {
			padding-bottom: 15px;
			flex-direction: column;
		}
		.text-image {
			flex-direction: column;
			text-align: center;

			.image {
				margin-right: 0;
				margin-bottom: 10px;
			}
		}
		.content {
			padding-top: 15px;
		}
		.info-actions {
			flex-direction: column;

			.actions,
			.button {
				width: 100%;
			}

			.price {
				margin-right: 0;
				margin-bottom: 5px;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
</style>